<div class="h-auto bg-metal pt-8 w-[100%]">
  <div class="container-customize flex-wrap w-auto m-auto lg:w-[1200px]">
    <div class="w-auto min-w-[330px] py-4 ps-4">
      <img
        class="xl:w-[260px] lg:w-[220px] md:w-[220px] sm:w-[220px] w-[200px] cursor-pointer img-logo"
        src="assets/img/logo-1.svg"
        routerLink="/" />
      <p
        class="xl:w-[330px] lg:w-[285px] md:w-[285px] sm:w-[285px] w-[257px] h-[25px] flex justify-center items-center mt-4">
        <a
          href="https://www.instagram.com/azone_construction"
          target="_blank"
          rel="noopener noreferrer">
          <i
            class="fab fa-instagram text-[#c1c1c1] hover:text-[#ffffff] cursor-pointer transition-all duration-300"
            style="font-size: 24px"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer">
          <i
            class="fab fa-facebook text-[#c1c1c1] hover:text-[#ffffff] cursor-pointer transition-all duration-300"
            style="font-size: 24px"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer">
          <i
            class="fab fa-twitter text-[#c1c1c1] hover:text-[#ffffff] cursor-pointer transition-all duration-300"
            style="font-size: 24px"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer">
          <i
            class="fab fa-linkedin text-[#c1c1c1] hover:text-[#ffffff] cursor-pointer transition-all duration-300"
            style="font-size: 24px"></i>
        </a>
      </p>
    </div>

    <div
      class="flex flex-wrap gap-12 md:w-auto sm:w-auto w-auto p-4 justify-start">
      <div class="w-auto">
        <h1
          class="text-[#757575] text-[20px] font-black cursor-pointer hover:text-[#ffffff] hover:transition-all hover:duration-[700ms]"
          routerLink="/expertises">
          {{ 'expertise.expertise' | translate | async }}
        </h1>

        <div class="grid grid-cols-2">
          <p
            *ngFor="let expertise of expertiseList; let i = index"
            class="py-1 text-[#c1c1c1] font-black cursor-pointer hover:text-[#ffffff] hover:transition-all hover:duration-[700ms] max-w-[200px]"
            [routerLink]="
              '/expertises/expertise/' + expertise.id + '/' + expertise.name
            ">
            {{ expertise.name | truncate: 20 }}
          </p>
        </div>
      </div>

      <div class="w-auto">
        <h1
          class="text-[#757575] text-[20px] font-black cursor-pointer hover:text-[#ffffff] hover:transition-all hover:duration-[700ms]"
          routerLink="/contact">
          {{ 'locations.locations' | translate | async }}
        </h1>
        <p
          *ngFor="let contact of contactsList"
          class="py-1 text-[#c1c1c1] font-black cursor-pointer hover:text-[#ffffff] hover:transition-all hover:duration-[700ms] max-w-[180px]"
          routerLink="/contact">
          {{ contact }}
        </p>
      </div>
      <div class="w-auto">
        <h1 class="text-[#757575] text-[20px] font-black">Legal.</h1>
        @for (legal of legalList; track legal) {
          <a [routerLink]="legal.link">
            <p
              class="py-1 text-[#c1c1c1] font-black cursor-pointe hover:text-[#ffffff] hover:transition-all hover:duration-[700ms]">
              {{ legal.label }}
            </p>
          </a>
        }
      </div>
    </div>
  </div>

  <div
    class="flex flex-wrap gap-2 lg:justify-center md:justify-center sm:justify-center justify-center w-[100%] px-14 py-8 text-[#aaaaaa]">
    <div class="w-auto flex items-center text-center">
      <p>© {{ date }} Azone Construction - All rights reserved</p>
    </div>
    <a href="https://duku.al/" target="_blank"> </a>
  </div>
</div>
