<div
  class="top-nav-container p-4 hidden lg:block"
  [ngClass]="{ 'top-nav-customise': isHomePage }">
  <img
    class="xl:w-[225px] lg:w-[225px] md:w-[180px] sm:w-[160px] w-[160px] fixed top-[13px] cursor-pointer"
    [ngClass]="{ 'img-customise': isHomePage }"
    src="assets/img/logo-2.svg"
    routerLink="/" />
  <p
    class="title fixed top-[69px] lg:text-[15px] md:text-[15px] sm:text-[14px] text-[12px] font-bold ms-1 mt-[3px]"
    [ngClass]="{
      'fade-out': isFadingOut,
      'fade-in': isFadingIn,
      'title-customise': isHomePage,
    }">
    {{ currentTitle }}
  </p>
  <div class="flex-auto flex justify-end">
    <div class="min-w-[600px] flex space-x-[5px] mt-[20px]">
      @if (isHomeActive()) {
        <a
          class="bg-link-style text-[#ffffff] flex items-center h-12 p-3 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:text-black"
          [ngClass]="{ 'link-customise': isHomePage }"
          routerLink="/"
          routerLinkActive="bg-link-style-active text-[#000000]">
          <h5 class="w-full font-semibold" @fadeInOut>
            {{ 'app.home' | translate | async }}
          </h5>
        </a>
      }
      @if (!isHomeActive()) {
        <a
          class="bg-link-style text-[#ffffff] flex items-center h-12 p-3 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:text-black"
          [ngClass]="{ 'link-customise': isHomePage }"
          routerLink="/">
          <h5 class="w-full font-semibold" @fadeInOut>
            {{ 'app.home' | translate | async }}
          </h5>
        </a>
      }
      @for (link of navbarList; track link) {
        <a
          class="bg-link-style text-[#ffffff] flex items-center h-12 p-3 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:text-black"
          [ngClass]="{ 'link-customise': isHomePage }"
          [routerLink]="link.routerLink[1]"
          routerLinkActive="bg-link-style-active text-[#000000]">
          <h5 class="w-full font-semibold" @fadeInOut>
            {{ link.label | translate | async }}
          </h5>
        </a>
      }
    </div>
  </div>
</div>

<div
  class="top-nav-mobile-container p-2 lg:hidden"
  [ngClass]="{ 'top-nav-customise': isHomePage }">
  <img
    class="w-[160px] cursor-pointer"
    [ngClass]="{ 'img-customise': isHomePage }"
    src="assets/img/logo-2.svg"
    routerLink="/" />
  <p
    class="title g:text-[15px] md:text-[15px] sm:text-[14px] text-[12px] text-[#ffffff] font-bold ms-1"
    [ngClass]="{
      'fade-out': isFadingOut,
      'fade-in': isFadingIn,
      'title-customise': isHomePage,
    }">
    {{ currentTitle }}
  </p>
  <button
    class="mobile-nav ml-auto"
    (click)="toggleMenu()"
    type="button"
    aria-controls="navbar-search"
    aria-expanded="false">
    <img
      *ngIf="!isMenuOpen"
      [ngClass]="{ 'mobile-menu': isHomePage }"
      src="../../../../../assets/icons/menu.svg"
      alt="Menu" />
    <img
      *ngIf="isMenuOpen"
      src="../../../../../assets/icons/close.svg"
      alt="Close" />
  </button>
</div>

<div
  *ngIf="isMenuOpen"
  class="mobile-container lg:hidden"
  [ngClass]="{ 'mobile-menu-visible': isMobileMenuActive }">
  <button
    class="mobile-nav lg:hidden"
    (click)="toggleMenu()"
    type="button"
    aria-controls="navbar-search"
    aria-expanded="false">
    <img
      *ngIf="isMenuOpen"
      src="../../../../../assets/icons/close.svg"
      height="50px" />
  </button>
  <div
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/12">
    @if (isHomeActive()) {
      <a
        class="flex items-center h-12 text-[#000000] p-2 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:bg-[#ffffff] hover:text-black"
        [ngClass]="{ 'link-customise': isHomePage }"
        routerLink="/"
        routerLinkActive="bg-[#ffffff] text-[#000000]">
        <h4 class="w-full font-semibold text-center" @fadeInOut>
          {{ 'app.home' | translate | async }}
        </h4>
      </a>
    }
    @if (!isHomeActive()) {
      <a
        class="flex items-center h-12 min-w-[75px] text-[#000000] p-2 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:bg-[#ffffff] hover:text-black"
        [ngClass]="{ 'link-customise': isHomePage }"
        (click)="toggleMenu()"
        routerLink="/">
        <h4 class="w-full font-semibold text-center" @fadeInOut>
          {{ 'app.home' | translate | async }}
        </h4>
      </a>
    }
    <div *ngFor="let link of navbarList" (click)="toggleMenu()">
      <a
        class="flex items-center h-12 min-w-[75px] text-[#000000] p-2 text-decoration-none rounded-lg transition-all duration-300 ease-in-out hover:bg-[#ffffff] hover:text-black"
        [ngClass]="{ 'link-customise': isHomePage }"
        [routerLink]="link.routerLink[1]"
        routerLinkActive="bg-[#ffffff] text-[#000000]">
        <h4 class="w-full font-semibold text-center" @fadeInOut>
          {{ link.label | translate | async }}
        </h4>
      </a>
    </div>
  </div>
</div>
